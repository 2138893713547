import React from "react"

// Types
import { IIcon } from "../../types"

const SocialTwitterSVG = ({ className, width, height, color }: IIcon) => (
  <svg
    className={className}
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 120 120"
    fill={color}
  >
    <g fillRule="evenodd">
      <path fill="none" d="M0 0h120v120H0z" />
      <path d="M85.578 41.537c2.67-1.685 4.719-4.356 5.68-7.539a25.065 25.065 0 01-8.209 3.308C80.697 34.654 77.336 33 73.616 33c-7.137 0-12.92 6.104-12.92 13.63 0 1.07.107 2.11.329 3.105-10.739-.57-20.26-5.99-26.64-14.243a14.172 14.172 0 00-1.748 6.856c0 4.727 2.281 8.9 5.75 11.35a12.463 12.463 0 01-5.858-1.705v.168c0 6.607 4.453 12.117 10.373 13.365-1.087.32-2.226.48-3.41.48-.832 0-1.645-.082-2.43-.242 1.643 5.414 6.417 9.36 12.074 9.466-4.426 3.658-9.999 5.834-16.052 5.834A24.41 24.41 0 0130 80.877C35.72 84.742 42.513 87 49.813 87c23.777 0 36.771-20.772 36.771-38.786 0-.593-.007-1.182-.033-1.763A27.018 27.018 0 0093 39.392a24.772 24.772 0 01-7.422 2.145z" />
    </g>
  </svg>
)

export default SocialTwitterSVG
