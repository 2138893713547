import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled, { css } from "styled-components"

// Types
import { IPageProvider } from "../types/"

// HOCs
import { withPage } from "../hocs/"

// Styling
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"

// Components
import { Row, Col } from "./layout/Grid"
import SocialMediaIconsComponent from "./SocialMediaIcons"
import Logo from "./Logo"
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"

// Config
// Themes alter the appearance of the footer based on the colour of the page
const THEME_LIGHT = "light"
const THEME_DARK = "dark"

// Utils
const getTheme = (color: string) => {
  switch (color) {
    case colors.black:
      return THEME_LIGHT
    default:
      return THEME_DARK
  }
}

interface INavItem {
  title: string
  url: string
}

interface IProps {
  page: IPageProvider
}

const SiteFooter = ({ page }: IProps) => {
  const { socialBlock, privacyPolicy } = useStaticQuery(graphql`
    query {
      socialBlock: prismicSocialBlock {
        data {
          title
          links {
            url
            type
          }
        }
      }
      privacyPolicy: prismicPrivacyPolicy {
        data {
          privacy_policy {
            url
          }
        }
      }
    }
  `)

  const navItems = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Cases",
      url: "/cases",
    },
    {
      title: "How we work",
      url: "/about",
    },
    {
      title: "Contact us",
      url: "/contact-us",
    },
    {
      title: "Whitepapers",
      url: "/whitepaper",
    },
    {
      title: "Our team",
      url: "/team",
    },
    {
      title: "Work at",
      url: "/vacancies",
    },
    {
      title: "Life in the Jungle",
      url: "/life-in-the-jungle",
    },
    {
      title: "Blog",
      url: "/blog",
    },
    {
      title: "Privacy policy",
      url: privacyPolicy.data.privacy_policy.url,
    },
  ]

  const [theme, setTheme] = useState<"light" | "dark">(THEME_DARK)

  useEffect(() => {
    setTheme(getTheme(page.color))
  }, [page.color])

  return (
    <Footer theme={theme}>
      <LogoRow>
        <Col xxs={7} xxsOffset={1} m={8} mOffset={7} l={6} lOffset={9}>
          <AnimatedScrollWrapper>
            <Logo theme={theme} wordmark />
          </AnimatedScrollWrapper>
        </Col>
      </LogoRow>
      <Content>
        <ContentRow>
          <Col m={8}>
            <AnimatedScrollWrapper>
              <NavList>
                {navItems
                  .slice(0, Math.ceil(navItems.length / 2))
                  .map((item: INavItem, index) => (
                    <NavItem key={index}>
                      {item.url.includes("http") || item.url.includes("www") ? (
                        <ExternalLink
                          href={item.url}
                          target="_blank"
                          theme={theme}
                        >
                          {item.title}
                        </ExternalLink>
                      ) : (
                        <InternalLink to={item.url} theme={theme}>
                          {item.title}
                        </InternalLink>
                      )}
                    </NavItem>
                  ))}
              </NavList>
            </AnimatedScrollWrapper>
          </Col>
          <Col m={8}>
            <AnimatedScrollWrapper>
              <NavList>
                {navItems
                  .slice(Math.ceil(navItems.length / 2))
                  .map((item: INavItem, index) => (
                    <NavItem key={index}>
                      {item.url.includes("http") || item.url.includes("www") ? (
                        <ExternalLink
                          href={item.url}
                          target="_blank"
                          theme={theme}
                        >
                          {item.title}
                        </ExternalLink>
                      ) : (
                        <InternalLink to={item.url} theme={theme}>
                          {item.title}
                        </InternalLink>
                      )}
                    </NavItem>
                  ))}
              </NavList>
            </AnimatedScrollWrapper>
          </Col>
        </ContentRow>

        <ContentRow>
          <Col>
            <AnimatedScrollWrapper>
              <AddressWrapper>
                <Address>
                  Jungle Minds
                  <br />
                  De groene draeck
                  <br />
                  Grasweg 79
                  <br />
                  1031 HX Amsterdam
                  <br />
                  <ExternalLink
                    href="tel:+310205141414"
                    title="Call our office"
                    theme={theme}
                  >
                    <span>+31 (0)20 514 14 14</span>
                  </ExternalLink>
                </Address>

                <Address>
                  <ExternalLink
                    href="mailto:info@jungleminds.nl"
                    title="Send us an email"
                    theme={theme}
                  >
                    <span>info@jungleminds.nl</span>
                  </ExternalLink>
                  <br />
                  KvK: 34342083
                  <br />
                  BTW: NL821125801B01
                  <br />
                  Days: M - F
                  <br />
                  Hours: 9 till 5
                </Address>
              </AddressWrapper>
            </AnimatedScrollWrapper>
          </Col>

          {socialBlock && socialBlock.data && socialBlock.data.links && (
            <Col>
              <AnimatedScrollWrapper>
                <SocialMediaWrapper>
                  <SocialMediaIcons
                    links={socialBlock.data.links}
                    theme={theme}
                  />
                </SocialMediaWrapper>
              </AnimatedScrollWrapper>
            </Col>
          )}
        </ContentRow>
      </Content>
    </Footer>
  )
}

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  /* duplicate style because of IE */
  margin-top: 12.5vw;
  margin-top: var(--base-column-size);
  padding: 12.5vw 6.25vw;
  padding: var(--base-column-size) calc(var(--base-column-size) / 2);
  color: ${({ theme }) => (theme === "dark" ? colors.black : colors.white)};

  ${mq.from.M`
    padding: 0;
    /* duplicate style because of IE */
    margin-top: 6.25vw;
    margin-top: var(--base-column-size);
  `}

  ${mq.from.L`
    padding-bottom: 9vw;
    padding-bottom: calc(var(--base-column-size) * 1.45);
  `}
`

const LogoRow = styled(Row)`
  /* duplicate style because of IE */
  margin-bottom: 7.5vw;
  margin-bottom: calc(var(--base-column-size) * 0.6);

  ${mq.from.M`
    margin-bottom: calc(1.25 * var(--base-column-size));
  `}

  ${mq.from.L`
    /* duplicate style because of IE */
    margin-bottom: 3.125vw;
    margin-bottom: calc(var(--base-column-size) / 2);
  `}

  ${mq.from.XL`
    /* duplicate style because of IE */
    margin-bottom: 4.68vw;
    margin-bottom: calc(0.75 * var(--base-column-size));
  `}
`

const Content = styled.div`
  ${mq.from.L`
    display: flex;
  `}
`

const ContentRow = styled(Row)`
  &:not(:last-of-type) {
    /* duplicate style because of IE */
    margin-bottom: 8.75vw;
    margin-bottom: calc(var(--base-column-size) * 0.7);

    ${mq.from.M`
      margin-bottom: 0
    `}
  }

  ${mq.from.M`
    /* duplicate style because of IE */
    padding-left: 6.25vw;
    padding-right: 6.25vw;
    padding-left: var(--base-column-size);
    padding-right: var(--base-column-size);
  `}

  ${mq.from.L`
    flex: 0 1 50%;
  `}
`

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  /* To include last child's bottom margin to the height of this element */
  overflow: auto;
`

const NavItem = styled.li`
  /* duplicate style because of IE */
  margin-bottom: 3.125vw;
  margin-bottom: calc(var(--base-column-size) / 4);

  ${mq.from.M`
    /* duplicate style because of IE */
    margin-bottom: 1.56vw;
    margin-bottom: calc(var(--base-column-size) / 4);
  `}
`

const linkStyles = css`
  ${textStyles.hyperlink}
  color: ${({ theme }) => (theme === "dark" ? colors.black : colors.white)};
`

const InternalLink = styled(Link)`
  ${linkStyles}
`

const ExternalLink = styled.a`
  ${linkStyles}
`

const AddressWrapper = styled.div`
  /* duplicate style because of IE */
  padding-bottom: 6.25vw;
  padding-bottom: calc(var(--base-column-size) / 2);

  ${mq.from.M`
    display: flex;
    flex-wrap: wrap;
    /* duplicate style because of IE */
    padding-bottom: 3.125vw;
    padding-bottom: calc(var(--base-column-size) / 2);
  `}

  ${mq.from.XXL`
    padding-bottom: 0;
  `}
`

const Address = styled.address`
  font-style: normal;
  margin-bottom: 0;

  ${mq.to.M`
    :first-child {
      margin-bottom: 24px;
    }
  `}

  ${mq.from.M`
    flex: 0 0 50%;
  `}

  ${mq.from.XL`
    margin-bottom: 0;
  `}
`

const SocialMediaWrapper = styled.div`
  margin-left: -10px;

  svg {
    max-width: 40px;
  }

  ${mq.from.M`
    margin-left: -15px;

    svg {
      max-width: 60px;
    }
  `}
`

const SocialMediaIcons = styled(SocialMediaIconsComponent)`
  justify-content: flex-start;
`

export default withPage(SiteFooter)
