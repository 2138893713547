import React from "react"

// Utils
import { PageContextConsumer } from "../utils/context/PageContext"

export const withPage = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  return function wrapWithPage(props: any) {
    return (
      <PageContextConsumer>
        {page => <WrappedComponent {...props} page={page} />}
      </PageContextConsumer>
    )
  }
}
