import React from "react"

// Utils
import { AppContextConsumer } from "../utils/context/AppContext"

export const withApp = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  return function wrapWithApp(props: any) {
    return (
      <AppContextConsumer>
        {app => <WrappedComponent {...props} app={app} />}
      </AppContextConsumer>
    )
  }
}
