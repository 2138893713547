import { getDistanceFromTop } from "../helpers/DOMHelpers"

export interface IScrollTracker {
  init: () => void
  unload: () => void
}

class ScrollTracker implements IScrollTracker {
  public state = {
    isTicking: false,
  }

  private options: any
  private cb: any
  private element: HTMLElement | Window

  constructor(options: any, cb: any) {
    this.options = options
    this.cb = cb
    this.element =
      this.options.element || (typeof window !== "undefined" && window)

    this.onScroll = this.onScroll.bind(this)
  }

  public unload() {
    this.element.removeEventListener("scroll", this.onScroll)
  }

  public bindListeners() {
    this.element.addEventListener("scroll", this.onScroll)
  }

  public init() {
    this.bindListeners()
  }

  private onScroll() {
    if (this.state.isTicking) {
      this.state.isTicking = true

      return
    }

    window.requestAnimationFrame(() => {
      this.cb(getDistanceFromTop(this.element))

      this.state.isTicking = false
    })
  }
}

export default ScrollTracker
