import React, { PureComponent } from "react"

// Types
import { IPageProvider, IParticleShapeType } from "../../types/"
import { IStaggeredParticle } from "../../utils/helpers/canvasHelpers"

// Utils
import { generatePattern } from "../../utils/helpers/canvasHelpers"
import debounce from "../../utils/performance/debounce"
import { sizes } from "../../styles/utils/mq"

// HOCs
import { withPage, withLocation } from "../../hocs/"

// Components
import PageTransitionsBackground from "./PageTransitionsBackground"
import PageTransitionsCanvas from "./PageTransitionsCanvas"

import Canvas, { ICanvas } from "../../../static/js/canvas/Canvas"
import Grid, { IGrid } from "../../../static/js/canvas/Grid"
import Particle, { IParticle } from "../../../static/js/canvas/Particle"

// Config
import { config } from "./config"

interface IProps {
  page: IPageProvider
  location: any
}

// No need for this to be component state or trigger lifecycle updates
let startTimestamp: number | null

// The difference in ms between the current and next frame
let frameDelta: number

// Utils
const getCellCount = () => {
  return window.innerWidth > sizes.M ? 16 : 8
}

class PageTransitionsEngine extends PureComponent<IProps> {
  public canvasRef: React.RefObject<HTMLCanvasElement> = React.createRef()
  public canvasEl?: HTMLCanvasElement
  public ctx?: CanvasRenderingContext2D | any

  private canvas: ICanvas | any
  private grid: IGrid | any

  // Init with default values
  private particles: IParticle[] = []
  private animationInterval: number = 0
  private raf: number = 0

  // Debounce to avoid calling the restart on every "resize" event
  private restartPatternAnimation = debounce(() => {
    if (this.props.page.animation.isInfinite) {
      this.stopInfiniteAnimation()
      this.playInfiniteAnimation()

      return
    }

    this.playAnimation()
  }, 500)

  public componentDidMount() {
    if (!this.canvasRef.current) {
      throw new Error("Canvas element not found.")
    }

    this.canvasEl = this.canvasRef.current
    this.ctx = this.canvasEl.getContext("2d")!

    // Setup instances
    this.canvas = new Canvas(this.canvasEl, this.ctx, config.canvas)
    this.grid = new Grid(this.ctx, config.grid)

    this.grid.updateXCellCount(getCellCount())

    this.canvas.init()
    this.grid.init()

    this.bindListeners()
  }

  // Animate on route update
  public componentDidUpdate(prevProps: any) {
    const { page, location } = this.props

    const prevBaseURL = prevProps.location.href.split("?")[0]
    const baseURL = location.href.split("?")[0]

    // No need to transition if for some reason the user's going back to the same page
    if (prevBaseURL === baseURL) {
      return
    }

    // Trigger an AppProvider update
    page.setIsAnimating(true)

    // Reset the next animation cycle starting timestamp
    startTimestamp = null

    // Make action "async" so that first the route is changed,
    // then the AppProvider new transition is set and only after that animate
    setTimeout(() => {
      if (page.animation.isInfinite) {
        this.playInfiniteAnimation()

        return
      }

      this.playAnimation()
    }, 0)
  }

  public render() {
    return (
      <>
        <PageTransitionsBackground />
        <PageTransitionsCanvas ref={this.canvasRef} />
      </>
    )
  }

  private bindListeners() {
    const { page } = this.props

    // Check whether the user is focused on the current browser tab
    // If not, the animation should be stopped for performance reasons
    document.addEventListener("visibilitychange", () => {
      document.visibilityState === "visible"
        ? requestAnimationFrame(this.tick)
        : cancelAnimationFrame(this.raf)
    })

    // The canvas and grid can resize in real-time without affecting performance,
    // however the particles will be regenerated on every animation cycle.
    window.addEventListener("resize", () => {
      this.grid.updateXCellCount(getCellCount())

      this.canvas.handleResize()
      this.grid.handleResize()

      if (page.animation.isInfinite) {
        this.restartPatternAnimation()
      }
    })
  }

  private playAnimation() {
    const { page } = this.props
    const pattern = this.setupPattern()

    setTimeout(() => this.setupAnimation(pattern), page.animation.startDelay)
  }

  private playInfiniteAnimation() {
    const { page } = this.props
    const pattern = this.setupPattern()

    const patternRepeatDelay = page.animation.duration + page.animation.endDelay

    setTimeout(() => {
      // Trigger first animation cycle
      this.setupAnimation(pattern)

      // Trigger repeating pattern
      this.animationInterval = setInterval(() => {
        this.setupAnimation(pattern)
      }, patternRepeatDelay)
    }, page.animation.startDelay)
  }

  private stopInfiniteAnimation() {
    clearInterval(this.animationInterval)
  }

  private getParticleOptions() {
    const { shouldTransitionColor, color, particle } = this.props.page

    if (shouldTransitionColor) {
      return {
        particleShape: "square" as IParticleShapeType,
        particleFadeIn: true,
        particleFadeOut: false,
        particleFadeInDuration: particle.fadeInDuration,
        particleFillStyle: color,
        particleShapeMultiplier: 1,
      }
    } else {
      return {
        particleShape: particle.shape,
        particleDuration: particle.duration,
        particleFadeIn: particle.fadeIn,
        particleFadeOut: particle.fadeOut,
        particleFadeInDuration: particle.fadeInDuration,
        particleFadeOutDuration: particle.fadeOutDuration,
        particleFillStyle: particle.fillStyle,
        particleStrokeStyle: particle.strokeStyle,
        particleShapeMultiplier: particle.shapeMultiplier,
      }
    }
  }

  /**
   * Pattern generation explanation and formulas
   *
   * pattern.duration = totalParticleLifetime + totalParticleDelays;
   *
   * The total particle lifetime will always be positive.
   * totalParticleLifetime = totalParticles * particle.duration;
   *
   * The total particle delays can be positive or negative.
   * The first particle starts at 0 and is not delayed, so we exclude it.
   * The particle delay can be positive or negative depending on
   * whether the next particle pops out before or after the previous one expires.
   * totalParticleDelays = (totalParticles - 1) * (particle.duration + particle.delay)
   *
   * Here, the delay either gets added or subtracted automatically.
   * We can therefore extract the formula for calculating the particle delay as
   * particle.delay = (pattern.duration - particle.duration) / maxStaggerIndex
   */
  private setupPattern() {
    const { animation, particle } = this.props.page
    const { xCells, yCells, cellSize } = this.grid.state
    const maxStaggerIndex = xCells + yCells

    if (particle.duration && particle.duration > animation.duration) {
      throw new Error(
        "The particle lifetime cannot exceed the total animation duration."
      )
    }

    const particleDelay =
      (animation.duration - (particle.duration || 0)) / maxStaggerIndex

    const options = {
      xCells,
      yCells,
      cellSize,
      direction: animation.direction,
      duration: animation.duration,
      easing: animation.easing,
      particleDelay,
      ...this.getParticleOptions(),
    }

    return generatePattern(options)
  }

  private setupAnimation(particles: IStaggeredParticle[]) {
    particles.forEach((particle: IStaggeredParticle) => {
      setTimeout(() => {
        this.particles.push(new Particle(this.ctx, particle.options))
      }, particle.delay)
    })

    this.raf = requestAnimationFrame(this.tick)
  }

  // The main loop function called on every rAF
  private tick = (timestamp: number) => {
    const { page } = this.props

    if (!page.isAnimating) {
      return
    }

    if (!startTimestamp) {
      startTimestamp = timestamp
    }

    // The elapsed time since starting a new animation cycle
    const elapsed = timestamp - startTimestamp

    // Exit anywhere before the very last frame to avoid racing conditions
    if (frameDelta && elapsed >= page.animation.duration - frameDelta) {
      // Reset page animation state
      page.setIsAnimating(false)

      // Reset particles array
      this.particles = []

      // Set new Canvas color
      this.canvas.update({ fill: page.color })
      this.canvas.draw()

      return
    }

    if (this.canvas && page.canvas.isEnabled) {
      this.canvas.draw()
    }

    if (this.grid && page.grid.isEnabled) {
      this.grid.draw()
    }

    if (this.particles && this.particles.length && page.animation.isEnabled) {
      const particles = this.particles.filter(
        (particle: IParticle) => particle.state.isAlive
      )

      particles.forEach((particle: IParticle) => particle.draw(timestamp))

      this.particles = particles
    }

    this.raf = requestAnimationFrame(newTimestamp => {
      frameDelta = newTimestamp - timestamp

      return this.tick(newTimestamp)
    })
  }
}

export default withPage(withLocation(PageTransitionsEngine))
