import React from "react"
import { Link as LinkComponent } from "gatsby"
import styled, { css } from "styled-components"

// Types
import { IAppProvider } from "../types/"

// HOCs
import { withApp } from "../hocs/"

// Styling
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import { duration, ease } from "../styles/animations"

interface INavItem {
  title: string
  url: string
}

// Setup
const DELAY_MULTIPLIER = 50
const LINK_BASE_DELAY = 100

const primaryNavItems = [
  {
    title: "Cases",
    url: "/cases",
  },
  {
    title: "How we work",
    url: "/about",
  },
  {
    title: "Contact us",
    url: "/contact-us",
  },
]

const secondaryNavItems = [
  {
    title: "Our team",
    url: "/team",
  },
  {
    title: "Work at",
    url: "/vacancies",
  },
  {
    title: "Life in the Jungle",
    url: "/life-in-the-jungle",
  },
  {
    title: "Blog",
    url: "/blog",
  },
  {
    title: "Social responsibility",
    url: "/social-responsibility",
  },
]

interface IProps {
  app: IAppProvider
}

const SiteNav = (props: IProps) => {
  const { app } = props

  return (
    <Nav isExpanded={app.isSiteHeaderExpanded}>
      <PrimaryNavList>
        {primaryNavItems.map((item: INavItem, index) => (
          <NavItem key={index}>
            <LinkDecoration
              isExpanded={app.isSiteHeaderExpanded}
              index={index}
              maxIndex={primaryNavItems.length}
            />
            <LinkWrapper
              isExpanded={app.isSiteHeaderExpanded}
              index={index}
              maxIndex={primaryNavItems.length}
            >
              <PrimaryLink
                to={item.url}
                onClick={() => app.collapseSiteHeader()}
              >
                {item.title}
              </PrimaryLink>
            </LinkWrapper>
          </NavItem>
        ))}
      </PrimaryNavList>

      <SecondaryNavList>
        {secondaryNavItems.map((item: INavItem, index) => (
          <NavItem key={index}>
            <LinkWrapper
              isExpanded={app.isSiteHeaderExpanded}
              index={index + primaryNavItems.length - 1}
              maxIndex={secondaryNavItems.length + primaryNavItems.length - 1}
            >
              <SecondaryLink
                to={item.url}
                onClick={() => app.collapseSiteHeader()}
              >
                {item.title}
              </SecondaryLink>
            </LinkWrapper>
          </NavItem>
        ))}
      </SecondaryNavList>
    </Nav>
  )
}

// Shared styles
const sharedNavListStyles = css`
  margin: 0;
  list-style: none;
`

const sharedLinkStyles = css`
  display: inline-block;
  padding-top: 0;
  color: ${colors.black};
  text-decoration: none;

  &:hover {
    color: ${colors.white};
  }
`

// Main styles
const Nav = styled.nav`
  visibility: ${(props: { isExpanded: boolean }) =>
    !props.isExpanded && "hidden"};
  margin-bottom: calc(var(--base-column-size) / 2);
  transition: visibility ${duration.slow}ms ${ease.default};
`

const PrimaryNavList = styled.ul`
  ${sharedNavListStyles};
  /* duplicate style because of IE */
  margin-bottom: 6.25vw;
  margin-bottom: var(--base-column-size);
`

const SecondaryNavList = styled.ul`
  ${sharedNavListStyles};
`

const NavItem = styled.li`
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  padding-bottom: 24px;

  ${mq.from.M`
    padding-bottom: 30px;
  `}

  ${mq.from.L`
    padding-bottom: calc(var(--base-column-size) / 4);
  `}
`

const LinkDecoration = styled.div<{
  isExpanded: boolean
  index: number
  maxIndex: number
}>`
  border-top: 1px solid ${colors.black};
  opacity: ${({ isExpanded }) => (isExpanded ? 1 : 0)};
  transition: opacity ${duration.medium}ms ${ease.cubic};
  transition-delay: ${({ isExpanded, index, maxIndex }) =>
    isExpanded
      ? `
        ${index * DELAY_MULTIPLIER}ms
      `
      : `
        ${(maxIndex - index) * DELAY_MULTIPLIER}ms
    `};
`

const LinkWrapper = styled.div<{
  isExpanded: boolean
  index: number
  maxIndex: number
}>`
  /* -110% is to hide descenders (tails) of letters like 'y' or 'g' */
  transform: ${({ isExpanded }) =>
    isExpanded ? "translateY(0%)" : "translateY(-110%)"};
  transition: transform ${duration.medium}ms ${ease.cubic};
  transition-delay: ${({ isExpanded, index, maxIndex }) =>
    isExpanded
      ? `
        ${index * DELAY_MULTIPLIER + LINK_BASE_DELAY}ms
      `
      : `
        ${(maxIndex - index) * DELAY_MULTIPLIER}ms
    `};
`

const PrimaryLink = styled(LinkComponent)`
  ${textStyles.headingL};
  ${sharedLinkStyles};

  ${mq.from.M`
    ${textStyles.headingXL};
  `}

  ${mq.from.L`
    ${textStyles.headingL};
  `}
`

const SecondaryLink = styled(LinkComponent)`
  ${textStyles.headingM};
  ${sharedLinkStyles};

  ${mq.from.M`
    ${textStyles.headingL};
  `}

  ${mq.from.L`
    ${textStyles.headingS};
  `}
`

export default withApp(SiteNav)
