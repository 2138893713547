import React from "react"
import styled, { css } from "styled-components"

// Types
import { ISocialBlockItem, TSocialPlatform } from "../types/"

// Styling
import colors from "../styles/colors"
import { mq } from "../styles/utils/mq"

// Components
import InstagramSVG from "./icons/social-instagram"
import FacebookSVG from "./icons/social-facebook"
import LinkedinSVG from "./icons/social-linkedin"
import YoutubeSVG from "./icons/social-youtube"
import TwitterSVG from "./icons/social-twitter"

type ITheme = "light" | "dark"

interface IProps {
  links: ISocialBlockItem[]
  className?: string
  theme?: ITheme
}

// Utils
const renderSocialIcon = (type: TSocialPlatform) => {
  switch (type.toLowerCase()) {
    case "facebook":
      return <FacebookSVG />
    case "instagram":
      return <InstagramSVG />
    case "twitter":
      return <TwitterSVG />
    case "linkedin":
      return <LinkedinSVG />
    case "youtube":
      return <YoutubeSVG />
    default:
      return null
  }
}

const SocialMediaIcons = ({ className, links, theme = "dark" }: IProps) => (
  <Wrapper className={className}>
    {links &&
      links.length &&
      links.map((item: ISocialBlockItem, index: number) => (
        <SocialItem key={index}>
          {item.type && item.url && (
            <SocialLink href={item.url} target="_blank" theme={theme}>
              {renderSocialIcon(item.type)}
            </SocialLink>
          )}
        </SocialItem>
      ))}
  </Wrapper>
)

// Shared styles
const SocialIconStyles = css`
  width: 100%;
  max-width: 60px;
  max-height: 60px;
  fill: ${({ theme }) => (theme === "dark" ? colors.black : colors.white)};

  ${mq.from.M`
    /* duplicate style because of IE */
    max-width: 10vw;
    max-height: 10vw;
    max-width: calc(1.5 * var(--base-column-size));
    max-height: calc(1.5 * var(--base-column-size));
  `}

  ${mq.from.XL`
    max-width: 120px;
    max-height: 120px;
  `}
`

// Main styles
const Wrapper = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0;

  ${mq.from.M`
    justify-content: space-between;
  `}
`

const SocialItem = styled.li`
  margin-bottom: 0;
  line-height: 0;
`

const SocialLink = styled.a<{ theme: ITheme }>`
  display: block;

  &:hover {
    svg {
      fill: ${colors.accent1};
    }
  }

  svg {
    ${SocialIconStyles};
  }
`

export default SocialMediaIcons
