import { ILogState } from "../context/LogContext"

export interface IAction {
  type: "LOG_CRITICAL" | "LOG_ERROR" | "LOG_WARNING" | "LOG_INFO" | "LOG_DEBUG"
  payload: any
}

const LogReducer = (state: ILogState, { type, payload }: IAction) => {
  switch (type) {
    case "LOG_CRITICAL":
    case "LOG_ERROR":
    case "LOG_WARNING":
    case "LOG_INFO":
    case "LOG_DEBUG":
      const loggerType = type.split("_")[1].toLocaleLowerCase()

      state.errorLogger[`${loggerType}`](payload)
      return {
        ...state,
      }

    default:
      return {
        ...state,
      }
  }
}

export default LogReducer
