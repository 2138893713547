import React, { PureComponent, createRef } from "react"
import styled from "styled-components"
import createFocusTrap, { FocusTrap as IFocusTrap } from "focus-trap"

// Types
import { IAppProvider } from "../types/"

// Styling
import { mq } from "../styles/utils/mq"
import zIndices from "../styles/zIndices"
import colors from "../styles/colors"
import { duration, ease } from "../styles/animations"

// HOCs
import { withApp, withScrollTracker } from "../hocs/"

// Components
import SiteNavBar from "./SiteNavBar"
import SiteNav from "./SiteNav"

interface IProps {
  app: IAppProvider
  scrollY: number
}

// Setup
// By setting a scroll threshold, we can ignore micro-movements
// or slow, continuous scrolling such as when reading
const SCROLL_THRESHOLD = 10

class SiteHeader extends PureComponent<IProps, {}> {
  private wrapperRef: { current: HTMLDivElement | null }
  private focusTrap: IFocusTrap | any

  public constructor(props: IProps) {
    super(props)

    // Initialise class properties
    this.wrapperRef = createRef()
    this.focusTrap = {}
  }

  public componentDidMount() {
    // Exclamation (!) mark asserts that the element cannot be null or undefined
    this.focusTrap = createFocusTrap(this.wrapperRef.current!)

    this.bindListeners()
  }

  public componentDidUpdate(prevProps: any) {
    const { app, scrollY } = this.props

    // Toggle the focus trap
    app.isSiteHeaderExpanded
      ? this.focusTrap.activate()
      : this.focusTrap.deactivate()

    // Toggle header visibility
    if (!app.isSiteHeaderExpanded && scrollY !== prevProps.scrollY) {
      // Check for overscroll, e.g. if user's bouncing off the top of the window on Mac/iOS
      if (scrollY <= 0) {
        app.setIsSiteHeaderVisible(true)
      }
      // Scrolling up, but ignore overscrolling at the bottom of the page
      else if (
        scrollY < prevProps.scrollY - SCROLL_THRESHOLD &&
        scrollY < document.documentElement.scrollHeight - window.innerHeight
      ) {
        app.setIsSiteHeaderVisible(true)
      }
      // Scrolling down
      else if (scrollY > prevProps.scrollY + SCROLL_THRESHOLD) {
        app.setIsSiteHeaderVisible(false)
      }
    }
  }

  public render() {
    const { app } = this.props

    return (
      <Wrapper ref={this.wrapperRef}>
        <SiteNavBar />
        <Header isExpanded={app.isSiteHeaderExpanded}>
          <Content>
            <SiteNav />
          </Content>
        </Header>
        <Overlay isExpanded={app.isSiteHeaderExpanded} />
      </Wrapper>
    )
  }

  private bindListeners() {
    document.addEventListener("keydown", e => {
      // Check for ESC key
      if (e.keyCode === 27 && this.props.app.isSiteHeaderExpanded) {
        this.props.app.collapseSiteHeader()
      }
    })
  }
}

// Main styles
const Wrapper = styled.div``

const Overlay = styled.div`
  position: fixed;
  z-index: ${zIndices.siteHeaderOverlay};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.accent1};
  transform-origin: top center;
  transform: ${(props: { isExpanded: boolean }) =>
    props.isExpanded ? "scale3d(1, 1, 1)" : "scale3d(1, 0, 1)"};
  transition: transform ${duration.medium}ms ${ease.cubic};

  /* Ideally, this should be done using react-spring to keep the animations in sync */
  transition-delay: ${(props: { isExpanded: boolean }) =>
    props.isExpanded ? "0" : "250ms"};
`

const Header = styled.header<{ isExpanded: boolean }>`
  position: fixed;
  z-index: ${zIndices.siteHeader};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 18.75vw;

  /* hide scrollbar in IE and Edge */
  -ms-overflow-style: none;

  ${mq.from.M`
    padding-top: 9.375vw;
  `}

  /* Override IE styles */
  padding-top: calc(var(--base-column-size) * 1.5);

  ${({ isExpanded }) =>
    isExpanded
      ? `
      overflow-x: hidden;
      overflow-y: auto;
    `
      : "visibility: hidden;"}

  /* Custom text highlighting */
  *::-moz-selection {
    background-color: rgba(0, 0, 0, 0.99);
    color: ${colors.accent1};
  }

  *::selection {
    background-color: rgba(0, 0, 0, 0.99);
    color: ${colors.accent1};
  }

  /* Add a cover for the content to scroll under */
  &::before {
    content: "";
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    ${(props: { isExpanded: boolean }) =>
      props.isExpanded && "max-height: 18.75vw;"}
    background-color: ${colors.accent1};
    transition: max-height ${duration.medium}ms ${ease.cubic};

    ${mq.from.M`
      ${(props: { isExpanded: boolean }) =>
        props.isExpanded && "max-height: 9.375vw;"}
    `}

    /* Override IE styles */
    ${({ isExpanded }) =>
      isExpanded
        ? `
        max-height: calc(var(--base-column-size) * 1.5);
      `
        : "max-height: 0"};
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  /* Add some "safe" space for phones that display their navbar at the bottom */
  padding: 12px 12px 80px;

  ${mq.from.L`
    padding-bottom: 30px;
  `}
`

export default withApp(withScrollTracker(SiteHeader))
