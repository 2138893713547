// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-case-case-template-container-tsx": () => import("/opt/build/repo/src/templates/case/caseTemplateContainer.tsx" /* webpackChunkName: "component---src-templates-case-case-template-container-tsx" */),
  "component---src-templates-blog-blog-template-container-tsx": () => import("/opt/build/repo/src/templates/blog/blogTemplateContainer.tsx" /* webpackChunkName: "component---src-templates-blog-blog-template-container-tsx" */),
  "component---src-templates-whitepaper-whitepaper-template-container-tsx": () => import("/opt/build/repo/src/templates/whitepaper/whitepaperTemplateContainer.tsx" /* webpackChunkName: "component---src-templates-whitepaper-whitepaper-template-container-tsx" */),
  "component---src-templates-vacancy-vacancy-template-container-tsx": () => import("/opt/build/repo/src/templates/vacancy/vacancyTemplateContainer.tsx" /* webpackChunkName: "component---src-templates-vacancy-vacancy-template-container-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/opt/build/repo/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("/opt/build/repo/src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cases-tsx": () => import("/opt/build/repo/src/pages/cases.tsx" /* webpackChunkName: "component---src-pages-cases-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("/opt/build/repo/src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-life-in-the-jungle-tsx": () => import("/opt/build/repo/src/pages/life-in-the-jungle.tsx" /* webpackChunkName: "component---src-pages-life-in-the-jungle-tsx" */),
  "component---src-pages-radio-tsx": () => import("/opt/build/repo/src/pages/radio.tsx" /* webpackChunkName: "component---src-pages-radio-tsx" */),
  "component---src-pages-social-responsibility-tsx": () => import("/opt/build/repo/src/pages/social-responsibility.tsx" /* webpackChunkName: "component---src-pages-social-responsibility-tsx" */),
  "component---src-pages-team-tsx": () => import("/opt/build/repo/src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-typo-tsx": () => import("/opt/build/repo/src/pages/typo.tsx" /* webpackChunkName: "component---src-pages-typo-tsx" */),
  "component---src-pages-vacancies-tsx": () => import("/opt/build/repo/src/pages/vacancies.tsx" /* webpackChunkName: "component---src-pages-vacancies-tsx" */),
  "component---src-pages-whitepaper-tsx": () => import("/opt/build/repo/src/pages/whitepaper.tsx" /* webpackChunkName: "component---src-pages-whitepaper-tsx" */)
}

