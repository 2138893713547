import React from "react"

// Types
import { IPageProvider } from "../../types"

// Utils
import { PageContextProvider, initialState } from "../context/PageContext"

class PageProvider extends React.PureComponent<{}, IPageProvider> {
  public state = {
    ...initialState,
    setIsAnimating: (bool: boolean) => this.setIsAnimating(bool),
    updateColor: (color: string) => this.updateColor(color),
    updateTransition: (args: any) => this.updateTransition(args),
  }

  public render() {
    return (
      <PageContextProvider value={this.state}>
        {this.props.children}
      </PageContextProvider>
    )
  }

  public setIsAnimating = (bool: boolean) => {
    this.setState({ isAnimating: bool })
  }

  public updateColor = (color: string) => {
    const isSameColor = color === this.state.color

    this.setState({
      shouldTransitionColor: !isSameColor,
      color,
    })
  }

  public updateTransition = (args: any) => {
    this.setState({ ...args })
  }
}

export default PageProvider
