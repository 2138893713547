import React, { PureComponent } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

// Types
import { IAppProvider, IPageProvider } from "../types/"

// HOCs
import { withApp, withPage } from "../hocs/"

// Components
import ContainerComponent from "./layout/Container"
import LogoComponent from "./Logo"
import CrossSVGComponent from "./icons/cross"

// Styling
import { mq } from "../styles/utils/mq"
import { duration, ease } from "../styles/animations"
import zIndices from "../styles/zIndices"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import { fonts, fontWeights } from "../styles/fonts"

interface IProps {
  app: IAppProvider
  page: IPageProvider
}

interface IState {
  theme: string
}

// Config
// Themes alter the appearance of the logo and toggle based on the colour of the page
const THEME_LIGHT = "light"
const THEME_DARK = "dark"

// Utils
const getTheme = (color: string) => {
  switch (color) {
    case colors.black:
      return THEME_LIGHT
    default:
      return THEME_DARK
  }
}

class SiteNavBar extends PureComponent<IProps, IState> {
  public state = {
    theme: THEME_DARK,
  }

  public componentDidMount() {
    this.updateTheme()
  }

  public componentDidUpdate(prevProps: any) {
    const { app, page } = this.props

    if (app.isSiteHeaderExpanded) {
      this.setState({
        theme: THEME_DARK,
      })
    } else if (!app.isSiteHeaderExpanded && page.color !== prevProps.color) {
      this.updateTheme()
    }
  }

  public render() {
    const { app } = this.props
    const { theme } = this.state

    return (
      <Wrapper isSiteHeaderVisible={app.isSiteHeaderVisible}>
        <Container>
          <LogoLink
            to="/"
            aria-label="Jungle Minds Homepage"
            tabIndex={0}
            onClick={() => app.collapseSiteHeader()}
            onFocus={() => app.setIsSiteHeaderVisible(true)}
          >
            <Logo theme={theme} wordmark />
          </LogoLink>

          <TryUsLink
            to="/"
            theme={theme}
            aria-label="Contact Us Page"
            tabIndex={0}
            onClick={() => app.collapseSiteHeader()}
            onFocus={() => app.setIsSiteHeaderVisible(true)}
          ></TryUsLink>

          <ToggleBtn
            aria-label="Main navigation"
            aria-pressed={app.isSiteHeaderExpanded}
            tabIndex={0}
            onClick={() => app.toggleSiteHeader()}
            onFocus={() => app.setIsSiteHeaderVisible(true)}
          >
            <CrossSVG
              isActive={app.isSiteHeaderExpanded}
              color={theme === "dark" ? colors.black : colors.white}
            />
          </ToggleBtn>
        </Container>
      </Wrapper>
    )
  }

  private updateTheme() {
    this.setState({ theme: getTheme(this.props.page.color) })
  }
}

// Shared styles
const sharedSVGStyles = css`
  /* IE Fallback styles */
  height: 24px;

  ${mq.from.M`
    height: 32px;
  `}

  ${mq.from.L`
    height: 48px;
  `}

  ${mq.from.XXL`
    max-width: 25vw;
    height: 100%;
  `}

  /* Override IE styles */
  height: calc(var(--base-column-size) / 2);
`

// Main styles
const Wrapper = styled.div<{ isSiteHeaderVisible: boolean }>`
  position: fixed;
  z-index: ${zIndices.siteNavBar};
  top: 0;
  right: 0;
  left: 0;
  height: var(--base-column-size);
  transform-origin: top center;
  transition: transform ${duration.slow}ms ${ease.default};
  transform: ${({ isSiteHeaderVisible }) =>
    isSiteHeaderVisible
      ? `translate3d(0, 0, 0);`
      : `translate3d(0, -100%, 0);`};

  ${mq.from.M`
    height: 6.25vw;
  `}

  /* Override IE styles */
  height: 12.5vw;
`

const Container = styled(ContainerComponent)`
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 8px;
  padding-bottom: 8px;

  ${mq.from.XXL`
    padding-top: 30px;
    padding-bottom: 30px;
  `}
`

const LogoLink = styled(Link)`
  line-height: 0;
  text-decoration: none;
  margin-right: auto;

  ${mq.from.XXL`
    height: 100%;
  `}
`

const Logo = styled(LogoComponent)`
  ${sharedSVGStyles};
`

const TryUsLink = styled(Link)<{ theme: string }>`
  font-family: ${fonts.primary};
  font-weight: ${fontWeights.bold};
  ${textStyles.hyperlink}
  color: ${({ theme }) => (theme === "dark" ? colors.black : colors.white)};
  text-decoration: none;

  /* IE Fallback styles */
  margin-right: 24px;

  ${mq.from.M`
    margin-right: 32px;
  `}

  ${mq.from.L`
    margin-right: 48px;
  `}

  ${mq.from.XXL`
    max-width: 25vw;
    margin-right: 60px;
  `}

  /* Override IE styles */
  margin-right: calc(var(--base-column-size) / 2);
`

const ToggleBtn = styled.button`
  height: 100%;
  padding: 0;
  background: none;
  border: none;
  line-height: 0;
  cursor: pointer;
`

const CrossSVG = styled(CrossSVGComponent)`
  ${sharedSVGStyles};
`

export default withApp(withPage(SiteNavBar))
