// Types
import { IParticleShapeType } from "../../types/"

// Styling
import { duration } from "../../styles/animations"
import colors from "../../styles/colors"

// Utils
import { easing } from "../../utils/animation/easings"

interface IConfig {
  canvas: {
    isEnabled: boolean
    fill: string
  }
  grid: {
    isEnabled: boolean
    xCells: number
    opacity: number
    strokeStyle: string
    lineWidth: number
  }
  animation: {
    isEnabled: boolean
    isInfinite?: boolean
    direction: string
    duration: number
    startDelay: number
    endDelay: number
    easing: any
  }
  particle: {
    shape: IParticleShapeType
    duration?: number
    fadeIn?: boolean
    fadeOut?: boolean
    fadeInDuration?: number
    fadeOutDuration?: number
    fillStyle?: string | any
    strokeStyle?: string | any
    shapeMultiplier?: number | any
  }
}

export const config: IConfig = {
  canvas: {
    isEnabled: true,
    fill: "transparent",
  },
  grid: {
    isEnabled: false,
    xCells: 16,
    opacity: 0.25,
    strokeStyle: colors.black,
    lineWidth: 1,
  },
  animation: {
    isEnabled: true,
    direction: "to-bottom-right",
    duration: duration.pagePatternTransition,
    startDelay: duration.pageContentTransition, // Wait for content to fade out
    endDelay: 0,
    easing: easing.easeInCubic,
  },
  particle: {
    shape: "arc",
    fillStyle: colors.black,
    duration: 600,
    fadeInDuration: 125,
    fadeOutDuration: 125,
    shapeMultiplier: ({ maxStaggerIndex, i, j }: any) =>
      (i + j) / maxStaggerIndex,
  },
}
