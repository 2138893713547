import React from "react"

// Types
import { IIcon } from "../../types"

const SocialYoutubeSVG = ({ className, width, height, color }: IIcon) => (
  <svg
    className={className}
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 120 120"
    fill={color}
  >
    <g fillRule="evenodd">
      <path fill="none" d="M0 0h120v120H0z" />
      <path d="M60.342 34.138c2.85.007 22.582.112 27.787 1.544 3.099.85 5.538 3.355 6.367 6.537 1.439 5.514 1.501 16.76 1.504 17.728v.134c-.003.967-.065 12.213-1.504 17.728-.829 3.181-3.268 5.686-6.367 6.537-5.264 1.448-25.383 1.538-27.877 1.544h-.508c-2.495-.006-22.614-.096-27.878-1.544-3.098-.85-5.538-3.356-6.366-6.537-1.417-5.432-1.5-16.422-1.504-17.677v-.236C24 58.64 24.083 47.65 25.5 42.22c.828-3.182 3.268-5.687 6.366-6.537 5.206-1.432 24.938-1.537 27.788-1.544zm-7.094 15.75V72.39l18-11.25-18-11.252z" />
    </g>
  </svg>
)

export default SocialYoutubeSVG
