import React, { useState, useEffect, ReactNode } from "react"
import styled, { css } from "styled-components"
import { useInView } from "react-intersection-observer"

// Styling
import { ease, enterOnScroll } from "../../styles/animations"

interface IProps {
  className?: string
  children: ReactNode
  delay?: number
}

// Setup
const ANIMATION_DELAY = 0.5
const ANIMATION_DURATION = 1.8

const AnimatedScrollWrapper: React.FC<IProps> = ({
  className,
  children,
  delay = ANIMATION_DELAY,
}) => {
  // Convert to ms
  const timeoutDuration = (delay + ANIMATION_DURATION) * 1000

  // Use hooks
  const [isAnimationDone, setisAnimationDone] = useState(false)
  const [ref, inView] = useInView({
    rootMargin: "0px 0px 0px 0px",
    triggerOnce: true,
    threshold: 0,
  })

  useEffect(() => {
    if (inView) {
      const timeout = setTimeout(
        () => setisAnimationDone(true),
        timeoutDuration
      )

      return () => clearTimeout(timeout)
    }

    return
  }, [inView])

  return (
    <Wrapper ref={ref} className={className} isAnimationDone={isAnimationDone}>
      <Children inView={inView} delay={delay}>
        {children}
      </Children>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isAnimationDone: boolean }>`
  /**
   * Setting "hidden" will make elements appear from "invisible" containers.
   * Resetting to "visible" will allow the items to use their default overflow styling,
   * as well as show the default focus outline which would otherwise be hidden.
   */
  overflow: ${({ isAnimationDone }) =>
    isAnimationDone ? "visible" : "hidden"};
  width: 100%;
`

const Children = styled.div`
  width: inherit;
  height: inherit;
  /* Fixes subpixel calculations */
  transform: translateY(101%);

  ${({ inView, delay }: { inView: boolean; delay: number }) =>
    inView &&
    css`
      animation-name: ${enterOnScroll};
      animation-duration: ${ANIMATION_DURATION}s;
      animation-fill-mode: forwards;
      animation-timing-function: ${ease.animationWrapperCubic};
      ${delay && `animation-delay: ${delay}s;`};
    `};
`

export default AnimatedScrollWrapper
