import React from "react"

// Styling
import colors from "../../styles/colors"

// Config
import { config } from "../../components/pageTransitions/config"

export const initialState = {
  ...config,
  shouldTransitionColor: false,
  isAnimating: false,
  color: colors.white,
}

const PageContext = React.createContext<any | null>(initialState)

export const PageContextProvider = PageContext.Provider
export const PageContextConsumer = PageContext.Consumer
export default PageContext
