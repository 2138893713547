import styled from "styled-components"

// Styling
import { mq } from "../../styles/utils/mq"

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
  margin: 0 auto;

  ${mq.from.L`
  padding-right: 15px;
  padding-left: 15px;
  `}
`
export default Container
