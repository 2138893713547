const debounce = (cb: () => void, wait: number = 50) => {
  let timeout: number

  return () => {
    clearTimeout(timeout)
    timeout = setTimeout(() => cb(), wait)
  }
}

export default debounce
