import React, { Dispatch } from "react"
import Rollbar from "rollbar"
import { IAction } from "../reducer/LogReducer"

export interface ILogState {
  errorLogger: any
}

export interface ILogContext {
  state: ILogState
  dispatch: Dispatch<IAction>
}

export const initialState: ILogState = {
  errorLogger: new Rollbar({
    accessToken: process.env.GATSBY_ROLLBAR_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.NODE_ENV,
    },
  }),
}

const LogContext = React.createContext({} as ILogContext)

export const LogContextProvider = LogContext.Provider
export const LogContextConsumer = LogContext.Consumer
export default LogContext
