import React, { useContext } from "react"
import { Location } from "@reach/router"
import { TransitionGroup, CSSTransition } from "react-transition-group"

// Utils
import PageContext from "../../utils/context/PageContext"

// Config
const TRANSITION_CLASSNAMES = "page-anim-"

/**
 * Wrap any React elements that need to be animated during the page transition
 */
const PageTransitionsWrapper = React.memo(props => {
  const page = useContext(PageContext)

  const timeout = page.animation.startDelay + page.animation.duration

  return (
    <Location>
      {({ location }) => (
        <TransitionGroup>
          <CSSTransition
            key={location.pathname}
            timeout={timeout}
            classNames={TRANSITION_CLASSNAMES}
          >
            {props.children}
          </CSSTransition>
        </TransitionGroup>
      )}
    </Location>
  )
})

export default PageTransitionsWrapper
