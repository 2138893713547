import React from "react"
import { Location } from "@reach/router"
import queryString from "query-string"

export const withLocation = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => (props: any) => (
  <Location>
    {({ location, navigate }) => (
      <WrappedComponent
        {...props}
        location={location}
        navigate={navigate}
        search={location.search ? queryString.parse(location.search) : {}}
      />
    )}
  </Location>
)

export default withLocation
