import React, { ReactNode, useReducer } from "react"

// Utils
import { LogContextProvider, initialState } from "../context/LogContext"
import LogReducer from "../reducer/LogReducer"

const LogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(LogReducer, initialState)

  return (
    <LogContextProvider value={{ state, dispatch }}>
      {children}
    </LogContextProvider>
  )
}

export default LogProvider
