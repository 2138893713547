// Types
import { IParticleShapeType } from "../../types/"
import { IEasing } from "../animation/easings"

interface IPattern {
  xCells: number
  yCells: number
  cellSize: number
  direction: string
  duration: number
  easing: IEasing
  particleShape: IParticleShapeType
  particleDuration?: number
  particleFadeIn?: boolean
  particleFadeOut?: boolean
  particleFadeInDuration?: number
  particleFadeOutDuration?: number
  particleDelay: number
  particleFillStyle?: string | any
  particleStrokeStyle?: string | any
  particleShapeMultiplier?: number | any
}

export interface IStaggeredParticle {
  options: {
    x: number
    y: number
    baseSize: number
    fillStyle?: string
    strokeStyle?: string
    shapeMultiplier?: number
  }
  delay: number
}

// Get the DPI of the current window
export const getDPI = () => window.devicePixelRatio

// Get a cell's delay factor/index based on its position in the grid
export const getStaggerIndex = (direction: string) => (
  xCells: number,
  yCells: number,
  i: number,
  j: number
): number => {
  switch (direction) {
    case "to-top":
      return yCells - j
    case "to-right":
      return i
    case "to-bottom":
      return j
    case "to-left":
      return xCells - i
    case "to-top-right":
      return yCells - j + i
    case "to-bottom-right":
      return i + j
    case "to-bottom-left":
      return xCells - i + j
    case "to-top-left":
      return yCells - i - j
    // To right
    default:
      return i
  }
}

// Get the maximum delay factor/index for the given number of cells in a grid
export const getMaxStaggerIndex = (direction: string) => (
  xCells: number,
  yCells: number
): number => {
  let max: number

  switch (direction) {
    case "to-top":
    case "to-bottom":
      max = yCells
      break
    case "to-right":
    case "to-left":
      max = xCells
      break
    case "to-top-right":
    case "to-bottom-right":
    case "to-bottom-left":
    case "to-top-left":
      max = xCells + yCells
      break
    // Horizontal
    default:
      max = xCells
  }

  return Math.round(max)
}

// Dynamically generate a new pattern based on the provided grid, particle attributes, etc.
export const generatePattern = (pattern: IPattern): IStaggeredParticle[] => {
  const {
    xCells,
    yCells,
    cellSize,
    direction,
    duration,
    easing,
    particleShape,
    particleDuration,
    particleFadeIn,
    particleFadeOut,
    particleFadeInDuration,
    particleFadeOutDuration,
    particleDelay,
    particleFillStyle,
    particleStrokeStyle,
    particleShapeMultiplier,
  } = pattern

  const maxStaggerIndex = getMaxStaggerIndex(direction)(xCells, yCells)

  const particles = []

  for (let i = 0; i < xCells; i++) {
    for (let j = 0; j < yCells; j++) {
      // Curry functions
      const staggerIndex = getStaggerIndex(direction)(xCells, yCells, i, j)

      let ease = 1
      let easedDuration = 0
      if (easing) {
        const progress = (staggerIndex + 1) / maxStaggerIndex

        ease = easing(progress)
      }

      const easedParticleDelay = ease * staggerIndex * particleDelay

      // If the particle duration is not specified, then we'll assume
      // they should last until the end of the pattern
      if (!particleDuration) {
        easedDuration = duration - easedParticleDelay
      }

      const particleOptions = {
        startTime: easedParticleDelay,
        x: i * cellSize,
        y: j * cellSize,
        baseSize: cellSize,
        shape: particleShape,
        duration: easedDuration || particleDuration,
        fadeIn: particleFadeIn,
        fadeOut: particleFadeOut,
        fadeInDuration: particleFadeInDuration,
        fadeOutDuration: particleFadeOutDuration,
        fillStyle:
          particleFillStyle &&
          (typeof particleFillStyle === "string"
            ? particleFillStyle
            : particleFillStyle({ xCells, yCells, i, j })),
        strokeStyle:
          particleStrokeStyle &&
          (typeof particleStrokeStyle === "string"
            ? particleStrokeStyle
            : particleStrokeStyle({ xCells, yCells, i, j })),
        shapeMultiplier:
          particleShapeMultiplier &&
          (typeof particleShapeMultiplier === "number"
            ? particleShapeMultiplier
            : particleShapeMultiplier({
                maxStaggerIndex,
                xCells,
                yCells,
                i,
                j,
              })),
      }

      particles.push({
        options: particleOptions,
        delay: easedParticleDelay,
      })
    }
  }

  return particles
}
