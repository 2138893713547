/**
 * Add all site colours here.
 */
export default {
  black: "#1E1E1E",
  blackRGB: {
    red: 30,
    green: 30,
    blue: 30,
  },
  white: "#ffffff",
  whiteRGB: {
    red: 255,
    green: 255,
    blue: 255,
  },
  lGrey: "#F6F6F6",
  lGreyRGB: {
    red: 246,
    green: 246,
    blue: 246,
  },
  accent1: "#FA4616",
  accent1RGB: {
    red: 250,
    green: 70,
    blue: 22,
  },
  accent2: "#F6E203",
  accent2RGB: {
    red: 246,
    green: 226,
    blue: 3,
  },
}
