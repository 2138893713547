/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "intersection-observer"
import React from "react"

// Styling
import { duration } from "./src/styles/animations"

// Utils
import LogProvider from "./src/utils/providers/LogProvider"
import AppProvider from "./src/utils/providers/AppProvider"
import PageProvider from "./src/utils/providers/PageProvider"
import CookieBanner from "./src/components/CookieBanner"

const COMMIT = process.env.COMMIT_HASH || "N.A."
const VERSION = process.env.VERSION || "N.A."

export const onInitialClientRender = () => {
  console.info(`
    __            __        _                __   __
     | |  | |\\ | / _\` |    |__  |\\/| | |\\ | |  \\ /__\`
  \\__/ \\__/ | \\| \\__> |___ |___ |  | | | \\| |__/ .__/
  by: Jungle Minds
  Version: ${VERSION}
  Commit: ${COMMIT}
  `)
}

/* eslint-disable react/prop-types */
/**
 * Styles do not need to be re-rendered and thus sit together with Providers
 * and other top-level components
 *
 * For more details, see: https://www.gatsbyjs.org/docs/browser-apis/#wrapRootElement
 */
export const wrapRootElement = ({ element }) => (
  <LogProvider>
    <AppProvider>
      <PageProvider>{element}</PageProvider>
      <CookieBanner></CookieBanner>
    </AppProvider>
  </LogProvider>
)

/* eslint-disable no-undef */
/**
 * Delay scrolling to top on route change.
 *
 * When transitioning to a new page, by default Gatsby will scroll to top
 * as soon as the route is updated. This results in jarring animations
 * where a user clicks a link and they're instantly scrolled
 * before letting the animation play.
 *
 * For more details, see: https://github.com/gatsbyjs/gatsby/issues/5213#issuecomment-428368131
 */
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (typeof window === "undefined") {
    return
  }

  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), duration.slow)
  } else {
    const savedPosition = getSavedScrollPosition(location)

    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      duration.slow
    )
  }
  return false
}
