import React from "react"
import styled from "styled-components"

// Styles
import zIndices from "../../styles/zIndices"

/**
 * Render a standard canvas element on page
 *
 * *: This component will forward its canvas ref back to the Engine
 */
const PageTransitionsCanvas = React.forwardRef(
  (props: any, canvasRef: React.Ref<HTMLCanvasElement>) => (
    <Canvas ref={canvasRef} {...props} />
  )
)

const Canvas = styled.canvas`
  position: fixed;
  z-index: ${zIndices.pageTransitionsCanvas};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export default PageTransitionsCanvas
