import React from "react"

// Utils
import ScrollTracker, { IScrollTracker } from "../utils/services/ScrollTracker"

export const withScrollTracker = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  return class WrappedScrollTracker extends React.Component<P & {}> {
    public state = {
      scrollY: 0,
    }

    private scrollTracker: IScrollTracker

    constructor(props: any) {
      super(props)

      this.scrollTracker = new ScrollTracker({}, this.onScroll)
    }

    public onScroll = (scrollY: number) => {
      this.setState({ scrollY })
    }

    public componentDidMount() {
      this.scrollTracker.init()
    }

    public componentWillUnmount() {
      this.scrollTracker.unload()
    }

    public render() {
      return <WrappedComponent {...this.props} {...this.state} />
    }
  }
}
