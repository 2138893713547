import { css } from "styled-components"

// Styling
import { fonts, fontWeights } from "./fonts"
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"

// Shared styles
const sharedHeadingStyles = css`
  font-family: ${fonts.primary};
  font-weight: ${fontWeights.bold};
  text-rendering: optimizeLegibility;
`

/**
 * All the fonts are scalable as per the following rules:
 * - From vw 320px - 1920px, the sizes are controlled per each breakpoint.
 * - From 1920px and up, the sizes all scale as a factor of the --base-scalable-font-size
 * which ensures that all the fonts with grow linearly with the window size
 * 'til infinity and beyond.
 *
 * Additionally, all fonts have simple px fallbacks in case calculations are not supported for some reason.
 *
 * More info on the formula used to calculate font ranges below:
 * https://css-tricks.com/snippets/css/fluid-typography/
 */

// Main styles
export default {
  headingHuge: css`
    ${sharedHeadingStyles};
    /* 64px - 144px */
    font-size: 64px;
    font-size: calc(64px + (144 - 64) * ((100vw - 320px) / (768 - 320)));
    line-height: 1;
    letter-spacing: -2px;

    /* 144px - 256px */
    ${mq.from.M`
      font-size: 144px;
      font-size: calc(144px + (256 - 144) * ((100vw - 768px) / (1280 - 768)));
    `}

    /* 256px - 320px */
    ${mq.from.XL`
      font-size: 256px;
      font-size: calc(256px + (320 - 256) * ((100vw - 1280px) / (1920 - 1280)));
    `}

    /* 320px+ */
    ${mq.from.XXL`
      font-size: 320px;
      font-size: calc(var(--base-fluid-font-size) * 20);
      line-height: 0.86;
    `}
  `,
  headingXXL: css`
    ${sharedHeadingStyles};
    /* 48px - 96px */
    font-size: 48px;
    font-size: calc(48px + (96 - 48) * ((100vw - 320px) / (768 - 320)));
    line-height: 1;
    font-weight: normal;

    /* 96px - 160px */
    ${mq.from.M`
      font-size: 96px;
      font-size: calc(96px + (160 - 96) * ((100vw - 768px) / (1280 - 768)));
      letter-spacing: -0.8px;
    `}

    /* 160px - 240px */
    ${mq.from.XL`
      font-size: 160px;
      font-size: calc(160px + (240 - 160) * ((100vw - 1280px) / (1920 - 1280)));
      letter-spacing: -1.33px;
    `}

    /* 240px+ */
    ${mq.from.XXL`
      font-size: 240px;
      font-size: calc(var(--base-fluid-font-size) * 15);
      letter-spacing: -2px;
    `}
  `,
  headingXL: css`
    ${sharedHeadingStyles};
    /* 48px - 64px */
    font-size: 48px;
    font-size: calc(48px + (64 - 48) * ((100vw - 320px) / (768 - 320)));
    line-height: 1;

    /* 64px - 104px */
    ${mq.from.M`
      font-size: 64px;
      font-size: calc(64px + (104 - 64) * ((100vw - 768px) / (1280 - 768)));
      letter-spacing: -0.6px;
    `}

    /* 104px - 160px */
    ${mq.from.XL`
      font-size: 104px;
      font-size: calc(104px + (160 - 104) * ((100vw - 1280px) / (1920 - 1280)));
      letter-spacing: -1px;
    `}

    /* 120px+ */
    ${mq.from.XXL`
      font-size: 160px;
      font-size: calc(var(--base-fluid-font-size) * 10);
      letter-spacing: -1.5px;
    `}
  `,
  headingL: css`
    ${sharedHeadingStyles};
    /* 36px - 48px */
    font-size: 36px;
    font-size: calc(36px + (48 - 36) * ((100vw - 320px) / (768 - 320)));
    line-height: 1;
    font-weight: 400;

    /* 48px - 80px */
    ${mq.from.M`
      font-size: 48px;
      font-size: calc(48px + (80 - 48) * ((100vw - 768px) / (1280 - 768)));
      letter-spacing: -0.8px;
    `}

    /* 80px - 120px */
    ${mq.from.XL`
      font-size: 80px;
      font-size: calc(80px + (120 - 80) * ((100vw - 1280px) / (1920 - 1280)));
      letter-spacing: -0.67px;
    `}

    /* 120px+ */
    ${mq.from.XXL`
      font-size: 120px;
      font-size: calc(var(--base-fluid-font-size) * 7.5);
      letter-spacing: -1px;
    `}
  `,
  /* For the homepage titles */
  headingML: css`
    ${sharedHeadingStyles};
    /* 32px - 48px */
    font-size: 32px;
    font-size: calc(32px + (48 - 32) * ((100vw - 320px) / (768 - 320)));
    line-height: 1.125;

    /* 48px - 69px */
    ${mq.from.M`
      font-size: 48px;
      font-size: calc(48px + (69 - 48) * ((100vw - 768px) / (1280 - 768)));
      line-height: 1;
      letter-spacing: -0.3px;
    `}

    /* 69px - 104px */
    ${mq.from.XL`
      font-size: 69px;
      font-size: calc(69px + (104 - 69) * ((100vw - 1280px) / (1920 - 1280)));
      letter-spacing: -0.5px;
    `}

    /* 104px+ */
    ${mq.from.XXL`
      font-size: 104px;
      font-size: calc(var(--base-fluid-font-size) * 6.5);
      letter-spacing: -0.75px;
    `}
  `,
  headingM: css`
    ${sharedHeadingStyles};
    /* 24px - 32px */
    font-size: 24px;
    font-size: calc(24px + (32 - 24) * ((100vw - 320px) / (768 - 320)));
    line-height: 1.125;
    font-weight: 400;

    /* 32px - 52px */
    ${mq.from.M`
      font-size: 32px;
      font-size: calc(32px + (52 - 32) * ((100vw - 768px) / (1280 - 768)));
      letter-spacing: -0.3px;
    `}

    /* 52px - 80px */
    ${mq.from.XL`
      font-size: 52px;
      font-size: calc(52px + (80 - 52) * ((100vw - 1280px) / (1920 - 1280)));
      letter-spacing: -0.5px;
    `}

    /* 80px+ */
    ${mq.from.XXL`
      font-size: 80px;
      font-size: calc(var(--base-fluid-font-size) * 5);
      letter-spacing: -0.75px;
    `}
  `,
  headingS: css`
    ${sharedHeadingStyles};
    /* 18px - 24px */
    font-size: 18px;
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (768 - 320)));
    line-height: 1.125;
    font-weight: 400;

    /* 24px - 40px */
    ${mq.from.M`
      font-size: 24px;
      font-size: calc(24px + (40 - 24) * ((100vw - 768px) / (1280 - 768)));
      letter-spacing: -0.27px;
    `}

    /* 40px - 60px */
    ${mq.from.XL`
      font-size: 40px;
      font-size: calc(40px + (60 - 40) * ((100vw - 1280px) / (1920 - 1280)));
      letter-spacing: -0.5px;
    `}

    /* 80px+ */
    ${mq.from.XXL`
      font-size: 60px;
      font-size: calc(var(--base-fluid-font-size) * 3.75);
      letter-spacing: -0.67px;
    `}
  `,
  body: css`
    font-family: ${fonts.primary};
    font-size: 16px;
    line-height: 1.25;

    /* 18 - 24px */
    ${mq.from.XL`
      font-size: 18px;
      font-size: calc(18px + (24 - 18) * ((100vw - 1280px) / (1920 - 1280)));
    `}

    /* 24px + */
    ${mq.from.XXL`
      font-size: 24px;
      font-size: calc(var(--base-fluid-font-size) * 1.5);
      line-height: 1.4;
    `}
  `,
  hyperlink: css`
    color: ${colors.black};
    text-decoration: none;

    span {
      position: relative;
    }

    :hover {
      text-decoration: underline;
    }
  `,
}
