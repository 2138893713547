import React from "react"

// Types
import { IIcon } from "../../types"

const SocialFacebookSVG = ({ className, width, height, color }: IIcon) => (
  <svg
    className={className}
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 120 120"
    fill={color}
  >
    <g fillRule="evenodd">
      <path fill="none" d="M0 0h120v120H0z" />
      <path d="M66.944 96H52.312V61.496H45v-11.89h7.312V42.47C52.312 32.77 56.438 27 68.169 27h9.764v11.893H71.83c-4.567 0-4.869 1.662-4.869 4.763l-.02 5.95H78l-1.294 11.89h-9.764V96h.002z" />
    </g>
  </svg>
)

export default SocialFacebookSVG
