export interface IGrid {
  state: {
    cellSize: number
    xCells: number
    yCells: number
  }
  init: () => void
  draw: () => void
  updateXCellCount: (xCellCount: number) => void
  handleResize: () => void
}

class Grid implements IGrid {
  public state: {
    cellSize: number
    xCells: number
    yCells: number
  }

  private ctx: CanvasRenderingContext2D
  private options: {
    opacity: number
    strokeStyle: string
    lineWidth: number
  }

  constructor(ctx: CanvasRenderingContext2D, options: any) {
    this.ctx = ctx
    this.options = options

    this.state = {
      cellSize: 0,
      xCells: options.xCells,
      yCells: 0,
    }
  }

  public init() {
    this.resize()
  }

  public updateXCellCount(xCellCount: number) {
    this.state.xCells = xCellCount
  }

  public handleResize() {
    this.resize()
  }

  public draw() {
    this.ctx.save()
    this.ctx.globalAlpha = this.options.opacity

    // Draw horizontal lines
    this.ctx.beginPath()

    for (let x = 0; x <= window.innerHeight; x += this.state.cellSize) {
      this.ctx.moveTo(0, x)
      this.ctx.lineTo(window.innerWidth, x)
    }

    this.ctx.strokeStyle = this.options.strokeStyle
    this.ctx.lineWidth = this.options.lineWidth

    this.ctx.stroke()

    // Draw vertical lines
    this.ctx.beginPath()

    for (let y = 0; y <= window.innerWidth; y += this.state.cellSize) {
      this.ctx.moveTo(y, 0)
      this.ctx.lineTo(y, window.innerHeight)
    }

    this.ctx.strokeStyle = this.options.strokeStyle
    this.ctx.lineWidth = this.options.lineWidth

    this.ctx.stroke()

    this.ctx.restore()
  }

  private resize() {
    // Round cell size to nearest integer to avoid subpixel rendering issues.
    // Always round up to ensure that the grid is exactly the same width as the screen
    // or more, but never less.
    this.state.cellSize = Math.ceil(window.innerWidth / this.state.xCells)
    this.state.yCells = window.innerHeight / this.state.cellSize
  }
}

export default Grid
