import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import Cookies from "universal-cookie"

// Styling
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import { mq } from "../styles/utils/mq"

// Components
import { Row, Col } from "./layout/Grid"
import Container from "./layout/Container"

const getButtonTheme = (theme: "light" | "dark") => {
  switch (theme) {
    case "light":
      return themeLight
    case "dark":
      return themeDark
  }
}

const oneYearFromNow = () => {
  const date = new Date()
  date.setFullYear(date.getFullYear() + 1);
  return date
}

const CookieBanner = React.memo(() => {
  const [visible, setVisible] = useState(false)
  const cookies = new Cookies(null, { path: "/" })

  useEffect(() => {
    const hasAcceptedCookie = cookies.get("gatsby-gdpr-google-analytics")
    if (hasAcceptedCookie === undefined) {
      setVisible(true)
    }
  }, [])

  const saveAllCookies = () => {
    cookies.set("gatsby-gdpr-google-analytics", true, {
      expires: oneYearFromNow()
    })
    setVisible(false)
  }

  const saveFunctionalCookies = () => {
    cookies.set("gatsby-gdpr-google-analytics", false, {
      expires: oneYearFromNow()
    })
    setVisible(false)
  }

  return (
    <Wrapper visible={visible}>
      <Container>
        <Row>
          <Title>We use cookies!</Title>
        </Row>
        <ContentRow>
          <CustomCol xxl={10}>
            <Text>
              We use functional cookies to make our website work properly and
              analytical cookies to measure the use of our website. By clicking
              ‘’accept all’’ you agree to this. By selecting ‘’use only
              functional’’ you will proceed to our website using functional
              cookies only.{" "}
            </Text>
          </CustomCol>
          <CustomCol xxl={6}>
            <ButtonRow>
              <Button theme="light" onClick={saveFunctionalCookies}>
                Use only functional
              </Button>
              <Button theme="dark" onClick={saveAllCookies}>
                Accept all
              </Button>
            </ButtonRow>
          </CustomCol>
        </ContentRow>
      </Container>
    </Wrapper>
  )
})

// Theme styles
const themeLight = css`
  background-color: ${colors.white};
  color: ${colors.black};
  border: 1px solid ${colors.black};
`

const themeDark = css`
  background-color: ${colors.black};
  color: ${colors.white};
  border: none;
`

const ContentRow = styled(Row)`
  ${mq.to.XXL`
    flex-wrap: nowrap;
    gap: 100px;
  `}
  ${mq.to.XL`
    flex-wrap: wrap;
    gap: 10px;
  `}
`

const CustomCol = styled(Col)`
  display: flex;
  align-items: center;
`

const ButtonRow = styled(Row)`
  gap: 30px;
  flex-wrap: nowrap;
  flex-grow: 5;
  ${mq.to.XS`
    flex-wrap: wrap;
    gap: 5px;
  `}
`

const Wrapper = styled.div<{ visible: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  display: ${props => (props.visible ? "block" : "none")};
  padding: 40px 20px 20px;
  background-color: ${colors.white};
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
`

const Title = styled.h2`
  ${textStyles.headingS};
  font-size: 40px;
  font-weight: 450;
`

const Text = styled.p`
  font-size: 16px;
  text-align: left;
`

const Button = styled.button<{ theme: "dark" | "light" }>`
  ${({ theme }) => (theme ? getButtonTheme(theme) : themeDark)};
  font-size: 18px;
  width: 100%;
  height: 50px;
  &:hover {
    cursor: pointer;
  }
`

export default CookieBanner