import React from "react"

// Types
import { IIcon } from "../../types"

const SocialInstagramSVG = ({ className, width, height, color }: IIcon) => (
  <>
    <svg
      className={className}
      style={{
        width: `${String(width)}px`,
        height: `${String(height)}px`,
      }}
      viewBox="0 0 40 40"
      fill="none"
    >
      <g fillRule="evenodd">
        <path fill="none" d="M0 0h40v40H0z" />
        <path
          d="M13.865 10h12.27A3.87 3.87 0 0130 13.865v12.27A3.87 3.87 0 0126.135 30h-12.27A3.87 3.87 0 0110 26.135v-12.27A3.87 3.87 0 0113.865 10zM20 16.48c-1.175 0-2.214.58-2.854 1.467A3.505 3.505 0 0016.48 20c0 1.94 1.58 3.52 3.52 3.52 1.941 0 3.52-1.58 3.52-3.52 0-.767-.249-1.475-.666-2.053A3.516 3.516 0 0020 16.479zm-8.052 9.655c0 1.057.86 1.916 1.917 1.916h12.27c1.057 0 1.916-.86 1.916-1.916V22.05H25.07A5.478 5.478 0 0120 25.47a5.477 5.477 0 01-5.069-3.418H11.95v4.084zm13.122-8.188h-.004c.256.635.403 1.327.403 2.052 0-.726-.142-1.418-.4-2.052z"
          fill={color}
        />
      </g>
    </svg>
  </>
)

export default SocialInstagramSVG
