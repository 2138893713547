import React, { useContext, useEffect } from "react"

// Utils
import AppContext from "../../utils/context/AppContext"
import PageContext from "../../utils/context/PageContext"

/**
 * This component sets the body background color only AFTER the transition has finished
 * in order to keep the new patterns being drawn on top of the old backgrounds.
 */
const PageTransitionsBackground = React.memo(() => {
  const app = useContext(AppContext)
  const page = useContext(PageContext)

  useEffect(() => {
    if (!page.isAnimating) {
      app.setBgColor(page.color)
    }
  }, [page.isAnimating, page.color])

  return null
})

export default PageTransitionsBackground
