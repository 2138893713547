export type IEasing = (t: number) => number

export interface IEasings {
  linear: IEasing
  easeInOutSine: IEasing
  easeInQuad: IEasing
  easeOutQuad: IEasing
  easeInOutQuad: IEasing
  easeInCubic: IEasing
  easeOutCubic: IEasing
  easeInOutCubic: IEasing
}

// Credits go to Gaëtan Renaudeau
// https://gist.github.com/gre/1650294
export const easing: IEasings = {
  // no easing, no acceleration
  linear: t => t,
  // slow in, fast out
  easeInOutSine: t => (1 + Math.sin(Math.PI * t - Math.PI / 2)) / 2,
  // accelerating from zero velocity
  easeInQuad: t => t * t,
  // decelerating to zero velocity
  easeOutQuad: t => t * (2 - t),
  // acceleration until halfway, then deceleration
  easeInOutQuad: t => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
  // accelerating from zero velocity
  easeInCubic: t => t * t * t,
  // decelerating to zero velocity
  easeOutCubic: t => --t * t * t + 1,
  // acceleration until halfway, then deceleration
  easeInOutCubic: t =>
    t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
}
