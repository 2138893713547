/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useContext } from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

// Styling
import GlobalStyle from "../styles/index"
import { mq } from "../styles/utils/mq"
import { fontFaces } from "../styles/fonts"
import { duration, ease } from "../styles/animations"

// Utils
import AppContext from "../utils/context/AppContext"

// Components
import SiteHeader from "../components/SiteHeader"
import PageTransitionsEngine from "../components/pageTransitions/PageTransitionsEngine"
import PageTransitionsWrapper from "../components/pageTransitions/PageTransitionsWrapper"
import SiteFooter from "../components/SiteFooter"

const Layout: React.FC = ({ children }) => {
  const app = useContext(AppContext)

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={() => (
        <Wrapper>
          <GlobalStyle />

          <SiteHeader />

          <PageTransitionsEngine />

          <PageTransitionsWrapper>
            <Container>
              <Main isScrollLocked={app.isScrollLocked}>{children}</Main>
            </Container>
          </PageTransitionsWrapper>

          <SiteFooter />
        </Wrapper>
      )}
    />
  )
}

// Main styles
const Wrapper = styled.div`
  ${fontFaces};
`

const Container = styled.div`
  margin: 0 auto;
  padding-top: 12.5vw;

  ${mq.from.M`
    padding-top: 6.25vw;
  `}

  padding-top: var(--base-column-size);
`

const Main = styled.main`
  /* Hide the content to avoid flickers when the scroll is locked */
  transition: opacity ${duration.medium}ms ${ease.cubic};

  opacity: ${(props: { isScrollLocked: boolean }) =>
    props.isScrollLocked ? 0 : 1};
`

export default Layout
