// Types
import { IParticleShapeType } from "../../../src/types/"

// Utils
import { easing } from "../../../src/utils/animation/easings"

export interface IParticle {
  state: {
    isAlive: boolean
    alpha: number
  }
  draw(time: number): void
}

const defaults = {
  shape: "square",
  startTime: 0,
  endTime: 2000,
  startAlpha: 0,
  endAlpha: 1,
  fadeInFactor: 1 / 4,
  fadeOutFactor: 1 / 4,
  shapeMultiplier: 1,
}

class Particle implements IParticle {
  public state = {
    isAlive: true,
    alpha: 0,
  }

  private ctx: CanvasRenderingContext2D
  private x: number
  private y: number
  private shape: IParticleShapeType
  private baseSize: number
  private duration: number
  private fadeIn: boolean
  private fadeOut: boolean
  private fillStyle: string
  private strokeStyle: string
  private startAlpha: number
  private endAlpha: number
  private fadeInDuration: number
  private fadeOutDuration: number
  private shapeMultiplier: number
  private size: number
  private startAngle: number
  private endAngle: number
  private startTime: number
  private endTime: number
  private fadeInTime: number
  private fadeOutTime: number

  constructor(ctx: CanvasRenderingContext2D, options: any) {
    const {
      x,
      y,
      shape,
      baseSize,
      duration,
      fillStyle,
      strokeStyle,
      fadeIn,
      fadeOut,
      startAlpha,
      endAlpha,
      fadeInDuration,
      fadeOutDuration,
      shapeMultiplier,
    } = options

    this.ctx = ctx
    this.x = x
    this.y = y
    this.shape = shape || defaults.shape
    this.baseSize = baseSize
    this.duration = duration
    this.fillStyle = fillStyle
    this.strokeStyle = strokeStyle

    this.startTime = defaults.startTime
    this.endTime = defaults.endTime
    this.fadeIn = fadeIn !== false
    this.fadeOut = fadeOut !== false
    this.fadeInTime = defaults.startTime
    this.fadeOutTime = defaults.endTime
    this.startAlpha = startAlpha || defaults.startAlpha
    this.endAlpha = endAlpha || defaults.endAlpha
    this.fadeInDuration = fadeInDuration || 0
    this.fadeOutDuration = fadeOutDuration || 0
    // Avoid falsy 0 values
    this.shapeMultiplier =
      shapeMultiplier !== undefined ? shapeMultiplier : defaults.shapeMultiplier

    // @TODO: Shuld be customisable per type of shape with custom render method
    this.size = this.baseSize * this.shapeMultiplier
    this.startAngle = 0
    this.endAngle = this.startAngle + Math.PI / 2
  }

  public draw(timestamp: number) {
    if (!this.startTime) {
      this.calculateLifetime(timestamp)
    }

    if (timestamp >= this.endTime) {
      this.state.isAlive = false

      return
    }

    // Fade in
    if (timestamp <= this.fadeInTime) {
      if (!this.fadeIn) {
        this.state.alpha = this.endAlpha

        return
      }

      if (this.state.alpha < this.endAlpha) {
        const elapsedTime = timestamp - this.startTime
        const percentage = 1 / (this.fadeInDuration / elapsedTime)

        this.state.alpha = easing.easeInOutSine(percentage)
      }
    }

    // Visible
    if (timestamp > this.fadeInTime && timestamp < this.fadeOutTime) {
      this.state.alpha = this.endAlpha
    }

    // Fade out
    if (timestamp >= this.fadeOutTime) {
      if (!this.fadeOut) {
        this.state.alpha = this.endAlpha

        return
      }

      if (this.state.alpha > this.startAlpha) {
        const elapsedTime = this.endTime - timestamp
        const percentage = 1 - 1 / (this.fadeOutDuration / elapsedTime)

        this.state.alpha = this.endAlpha - easing.easeInOutSine(percentage)
      }
    }

    this.ctx.save()

    this.ctx.globalAlpha = this.state.alpha

    this.drawShape(this.shape)

    this.ctx.restore()
  }

  private calculateLifetime(initialTimestamp: number) {
    this.startTime = initialTimestamp
    this.endTime = this.startTime + this.duration

    this.fadeInTime = this.startTime + this.fadeInDuration
    this.fadeOutTime = this.endTime - this.fadeOutDuration
  }

  private drawShape(shape: IParticleShapeType) {
    switch (shape) {
      case "arc":
        this.ctx.beginPath()

        this.ctx.moveTo(this.x, this.y)
        this.ctx.arc(this.x, this.y, this.size, this.startAngle, this.endAngle)
        this.ctx.closePath()

        if (this.fillStyle) {
          this.ctx.fillStyle = this.fillStyle
          this.ctx.fill()
        }

        if (this.strokeStyle) {
          this.ctx.strokeStyle = this.strokeStyle
          this.ctx.stroke()
        }

        break
      // Square
      default:
        this.ctx.moveTo(this.x, this.y)

        if (this.fillStyle) {
          this.ctx.fillStyle = this.fillStyle
          this.ctx.fillRect(this.x, this.y, this.size, this.size)
        }

        if (this.strokeStyle) {
          this.ctx.strokeStyle = this.strokeStyle
          this.ctx.strokeRect(this.x, this.y, this.size, this.size)
        }
    }
  }
}

export default Particle
