import React from "react"

// Types
import { IAppProvider } from "../../types/"

// Utils
import { AppContextProvider, initialState } from "../context/AppContext"
import {
  NO_SCROLL_CLASSNAME,
  NO_OVERFLOW_CLASSNAME,
} from "../constants/classNames"

// Setup
const docElem = document.documentElement
const bodyElem = document.body

class AppProvider extends React.PureComponent<{}, IAppProvider> {
  public state = {
    ...initialState,
    setIsSiteHeaderVisible: (value: boolean) =>
      this.setIsSiteHeaderVisible(value),
    expandSiteHeader: () => this.expandSiteHeader(),
    collapseSiteHeader: () => this.collapseSiteHeader(),
    toggleSiteHeader: () => this.toggleSiteHeader(),
    lockScroll: () => this.lockScroll(),
    unlockScroll: () => this.unlockScroll(),
    lockOverflow: () => this.lockOverflow(),
    unlockOverflow: () => this.unlockOverflow(),
    setBgColor: (color: string) => this.setBgColor(color),
  }

  public render() {
    return (
      <AppContextProvider value={this.state}>
        {this.props.children}
      </AppContextProvider>
    )
  }

  public setIsSiteHeaderVisible = (value: boolean) => {
    this.setState({ isSiteHeaderVisible: value })
  }

  public expandSiteHeader = () => {
    this.setState({ isSiteHeaderExpanded: true })

    this.lockScroll()
  }

  public collapseSiteHeader = () => {
    this.setState({ isSiteHeaderExpanded: false })

    this.unlockScroll()
  }

  public toggleSiteHeader = () => {
    this.state.isSiteHeaderExpanded
      ? this.collapseSiteHeader()
      : this.expandSiteHeader()
  }

  public lockScroll = () => {
    this.setState(
      {
        yOffsetBeforeLock: window.pageYOffset,
        isScrollLocked: true,
      },
      () => {
        docElem.classList.add(NO_SCROLL_CLASSNAME)
        bodyElem.classList.add(NO_SCROLL_CLASSNAME)
      }
    )
  }

  public unlockScroll = () => {
    this.setState({ isScrollLocked: false })

    docElem.classList.remove(NO_SCROLL_CLASSNAME)
    bodyElem.classList.remove(NO_SCROLL_CLASSNAME)

    // Skip this if user's at the top of the window
    if (this.state.yOffsetBeforeLock === 0) {
      return
    }

    window.scrollTo(0, this.state.yOffsetBeforeLock)

    // Simulate negative scrolling to make the SiteHeader show again
    setTimeout(() => window.scrollTo(0, this.state.yOffsetBeforeLock - 1), 50)
  }

  public lockOverflow = () => {
    docElem.classList.add(NO_OVERFLOW_CLASSNAME)
    bodyElem.classList.add(NO_OVERFLOW_CLASSNAME)
  }

  public unlockOverflow = () => {
    docElem.classList.remove(NO_OVERFLOW_CLASSNAME)
    bodyElem.classList.remove(NO_OVERFLOW_CLASSNAME)
  }

  public setBgColor = (color: string) => {
    bodyElem.style.backgroundColor = color
  }
}

export default AppProvider
