import React from "react"

export interface IAppContext {
  isSiteHeaderExpanded: boolean
  isSiteHeaderVisible: boolean
  isScrollLocked: boolean
  yOffsetBeforeLock: number
}

export const initialState = {
  isSiteHeaderExpanded: false,
  isSiteHeaderVisible: true,
  isScrollLocked: false,
  yOffsetBeforeLock: 0,
}

const AppContext = React.createContext<any | null>(initialState)

export const AppContextProvider = AppContext.Provider
export const AppContextConsumer = AppContext.Consumer
export default AppContext
